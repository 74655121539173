import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "authorization-code-flow"
    }}>{`Authorization code flow`}</h1>
    <p>{`Connect to Planday directly from you own application to receive the required access token for accessing Plandays API resources. Follow the guidelines below to manage the authorization code flow.`}</p>
    <p>{`The Authorization Code grant type is used by API applications integrating to Plandays API to exchange an authorization code for an access token. Allow the user to trigger the authorisation flow from your system by implementing the following flow:`}</p>
    <ul>
      <li parentName="ul">{`Your system sends the user to Planday’s authentication server.`}</li>
      <li parentName="ul">{`The user is directed to the Planday authorization / consent screen and approves the app’s request.`}</li>
      <li parentName="ul">{`The user is redirected back to your system with an authorization code in the query string.`}</li>
      <li parentName="ul">{`Your system exchanges the authorization code for a refresh token and an access token.`}</li>
      <li parentName="ul">{`Your system automatically handles the continuous refresh token exchange.`}</li>
    </ul>
    <h3 {...{
      "id": "requirements-and-limitations"
    }}>{`Requirements and limitations`}</h3>
    <ul>
      <li parentName="ul">{`The Planday OpenApi requires HTTPS.`}</li>
      <li parentName="ul">{`Planday OAuth access tokens expire after 1 hour. After expiration, applications must generate a new OAuth access token using the refresh token received when the authorization was first granted.`}</li>
      <li parentName="ul">{`Refresh tokens do not expire. However, if you lose a refresh token or the user revokes the access, you must repeat the full OAuth authorization code flow to obtain a new OAuth access token and a refresh token.`}</li>
    </ul>
    <h3 {...{
      "id": "flow-for-authorization-code-exchange"
    }}>{`Flow for authorization code exchange`}</h3>
    <ol>
      <li parentName="ol">{`Authorization request to `}<a parentName="li" {...{
          "href": "http://id.planday.com/"
        }}>{`id.planday.com`}</a></li>
      <li parentName="ol">{`Consent page `}</li>
      <li parentName="ol">{`Authorization code capture`}</li>
      <li parentName="ol">{`Code exchange`}</li>
      <li parentName="ol">{`Refresh Token / Access Token`}</li>
      <li parentName="ol">{`Revoke token / Disconnect`}</li>
    </ol>
    <p><img alt="dia" src={require("./images/dia.png")} /></p>
    <h3 {...{
      "id": "1-authorization-request-to-idplandaycom"
    }}>{`1. Authorization request to `}<a parentName="h3" {...{
        "href": "http://id.planday.com/"
      }}>{`id.planday.com`}</a></h3>
    <p>{`Your application should redirect the user to the following address:
`}<inlineCode parentName="p">{`https://id.planday.com/connect/authorize?client_id={clientId}&response_type=code&redirect_uri={redirectUri}&scope={scopes}&state={state}`}</inlineCode></p>
    <p><inlineCode parentName="p">{`client_id`}</inlineCode>{`
`}{`[Required]`}{` The value for APP`}<em parentName="p">{` ID`}</em>{` you received when creating your application. You can check the ID on your portal by going to  “API Access” page on your Planday portal. `}</p>
    <p><inlineCode parentName="p">{`redirect_uri`}</inlineCode>{`
`}{`[Required]`}{` The URL value for `}<em parentName="p">{`Redirection URL`}</em>{` you set when you created your application. You can set up to 3 redirect urls for your application (use localhost for development purposes). Please remember to url encode this value.`}</p>
    <p><inlineCode parentName="p">{`response_type`}</inlineCode>{`
`}{`[Required]`}{` The value `}<em parentName="p">{`must`}</em>{` be `}<inlineCode parentName="p">{`code`}</inlineCode>{` indicating the code authorization grant type is required and an authorization code is desired in the response.`}</p>
    <p><inlineCode parentName="p">{`scope`}</inlineCode>{`
`}{`[Required]`}{` This should contain all the scopes you’ve selected when creating your application and the following scopes as well: `}<em parentName="p">{`openid offline_access`}</em>{`. It should be a space-separated list, please see example under state.
You can find the scope names under each API i the API documentation.`}</p>
    <p><inlineCode parentName="p">{`state`}</inlineCode>{`
`}{`[Optional]`}{` Use this parameter to pass a unique value that will be returned to you after user completes the authorization. Use this if you want to make your app more resilient to `}<a parentName="p" {...{
        "href": "https://owasp.org/www-community/attacks/csrf"
      }}>{`CSRF`}</a>{` attacks. `}</p>
    <pre><code parentName="pre" {...{}}>{`curl --location --request GET 'https://id.planday.com/connect/authorize?
client_id=f2370889-3ffe-46b6-83e7-1a20f5a20d2f&scope=openid%20offline_access%20employee:read&
redirect_uri=http://example.com/code&response_type=code&state=xyzABC123' 
`}</code></pre>
    <p>{`We recommend using one of our graphics for a button that will take the your users to Planday.`}</p>
    <p><img alt="Button_for_connecting_Planday" src={require("./images/Button_for_connecting_Planday.jpg")} /></p>
    <h3 {...{
      "id": "2-consent-page"
    }}>{`2. Consent page`}</h3>
    <p>{`User of your application will be redirect back to Planday’s login page. Here he will be able to either enter his portal address/or selected one of the portals to which he has recently logged into. He will be then shown a consent screen where all of the requested scopes for your application will be listed. After giving consent the user will be redirected to the URL specified in the authorization request`}</p>
    <h3 {...{
      "id": "3-authorization-code-capture"
    }}>{`3. Authorization code capture`}</h3>
    <p>{`Planday will redirect back to the url specified in the redirect_uri parameter. We will append to an HTTP query string to with values for code and state (if provided in auth requests).
`}<inlineCode parentName="p">{`{redirectURL}?code={code}&state={state}`}</inlineCode></p>
    <p><inlineCode parentName="p">{`code`}</inlineCode>{`
One-time use authorization code that you should use in order to obtain an access and refresh token.`}</p>
    <p><inlineCode parentName="p">{`state`}</inlineCode>{`
We will return the value you’ve provided with the authorization request, so that you can verify it and prevent `}<a parentName="p" {...{
        "href": "https://owasp.org/www-community/attacks/csrf"
      }}>{`CSRF`}</a>{` attacks.`}</p>
    <h3 {...{
      "id": "4-code-exchange"
    }}>{`4. Code exchange`}</h3>
    <p>{`You can now exchange the code for an OAuth access token. Your application will need to make the following POST request to Planday’s token endpoint:
`}<inlineCode parentName="p">{`https://id.planday.com/connect/token`}</inlineCode></p>
    <p>{`The body of the request should contain four parameters`}</p>
    <ul>
      <li parentName="ul">{`client_id - your application id`}</li>
      <li parentName="ul">{`grant_type - authorization_code`}</li>
      <li parentName="ul">{`code - authorization code you’ve extracted in the 3rd step`}</li>
      <li parentName="ul">{`redirect_uri - redirection url you’ve set up for your application`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`POST https://id.planday.com/connect/token
Content-Type: application/x-www-form-urlencoded

client_id={applicationId}
&grant_type=authorization_code
&code={code}
&redirect_uri=https://myapp.com/redirect
`}</code></pre>
    <h3 {...{
      "id": "5-access-token-and-refresh-token"
    }}>{`5. Access Token and Refresh Token`}</h3>
    <p>{`Planday will verify all of the provided parameters with the request. If everything goes well you will receive a response with data in JSON format containing the access token.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgyQjU1OEI2NDA3QTkwRTlCRjIzMzYyQUM2M0E3NTdDMjNFQ0FC",
    "access_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6IjgyQjU1OEI2NDA3QTkwRTlCRjIzMzYyQUM2M0E3NTdDMjNFQ0FCM",
    "expires_in": 3600,
    "token_type": "Bearer",
    "refresh_token": "VxLtcy_OWkWoPKqs4uFhTg",
    "scope": "openid shift:read offline_access"
}
`}</code></pre>
    <p>{`The returned JSON object will contain other properties such as:`}</p>
    <p><inlineCode parentName="p">{`id_token`}</inlineCode>{`
The token containing user identity details.`}</p>
    <p><inlineCode parentName="p">{`access_token`}</inlineCode>{`
The access token value.  Use this token to access Planday’s OpenApi resources.`}</p>
    <p><inlineCode parentName="p">{`expires_in`}</inlineCode>{`
The remaining lifetime of the access token measured in seconds.`}</p>
    <p><inlineCode parentName="p">{`refresh_token`}</inlineCode>{`
Token that should be used to acquire a new access token.`}</p>
    <p><inlineCode parentName="p">{`token_type`}</inlineCode>{`
This value will always be ‘Bearer’`}</p>
    <p><inlineCode parentName="p">{`scope`}</inlineCode>{`
Enumeration of scopes `}</p>
    <p>{`You can now use the access token to access Planday’s resources. To get some basic information about the company you can use the Portal Api. `}</p>
    <h3 {...{
      "id": "6-revoke-token--disconnect-from-planday"
    }}>{`6. Revoke token / Disconnect from Planday`}</h3>
    <p>{`In some cases your users would like to disconnect from Planday. To revoke a token you need to send a POST request from your application to revoke endpoint. `}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://id.planday.com/connect/revocation
Content-Type: application/x-www-form-urlencoded

client_id={yourAppId}
&token={refreshToken}
`}</code></pre>
    <p><inlineCode parentName="p">{`client_id`}</inlineCode>{`
`}{`[Required]`}{` The value for APP`}<em parentName="p">{` ID`}</em>{` you received when creating your application. You can check the ID on your portal by going to  “API Access” page on your Planday portal.
token
`}{`[Required]`}{` RefreshToken received in step 5`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      